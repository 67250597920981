import {
  isNonEmptyJson,
  isNonEmptyObject,
  isNonEmptyString,
  isNumber,
  isString,
} from '@rippling/utils/validationUtils';

import { AllLocales, TEMPLATE_REGEXP } from ':constants/i18n';
import { PhraseJobSourceFile } from ':types/phrase';
import {
  WpBlock, WpPost, WpPostMeta, WpPostTypes
} from ':types/wordpress';

import { attemptToParseJson, deepCloneJson } from './object-helpers';

export const EMPTY_OPTION_KEY = '__EMPTY_OPTION__';

export const PERIOD_PLACEHOLDER_KEY = '__PERIOD_PLACEHOLDER_KEY__';

const IGNORED_WP_ATTRS = new Set(['id', 'reuId']);
const IGNORED_WP_REUSABLE_ATTRS = new Set([
  'clientId',
  'postId',
  'reuId',
  'slug',
  'name',
  'position',
  'className',
]);

export const getPostLocalesOrFallback = (
  post: any,
  fallbackLocales: AllLocales[] = []
): AllLocales[] => {
  return post?.meta?.global?._locales || fallbackLocales;
};

export const getPostMetaOrFallback = (
  post: any,
  fallbackLocales: AllLocales[]
) => {
  const metaToUse = post?.meta || {};
  metaToUse.global = metaToUse.global || {};
  metaToUse.global._locales = getPostLocalesOrFallback(post, fallbackLocales);

  return metaToUse;
};

export const getTranslatableLocalesFromAllowList = (
  givenLocales: AllLocales[],
  allowedLocales: AllLocales[]
) => {
  return givenLocales.filter((loc) => {
    if (loc === AllLocales.EN_US) {
      return false;
    }

    return allowedLocales.includes(loc);
  });
};

export const parseRawWpPost = (post: any): WpPost => {
  if (!isNonEmptyObject(post)) {
    throw new Error(`Failed to parse Wp Post, received: ${post}`);
  }

  const parsedPost = Object.entries(post).reduce((parsed, [key, value]) => {
    parsed[key] = attemptToParseJson(value);

    return parsed;
  }, {} as WpPost);

  return parsedPost;
};

export const isValidRawWpPost = (post: any) => {
  // must be an object...
  if (!isNonEmptyObject(post)) {
    return false;
  }

  const isPageType = post.wp?.type === 'page';

  // must not be missing, empty or null
  const blocksAreFalsy = !post.blocks?.length || post.blocks === '[]';

  // if its a page type, blocks are required
  if (isPageType && blocksAreFalsy) {
    return false;
  }

  return true;
};

export const attemptToParseRawWpPost = (page: any, fallbackValue: any) => {
  if (!isValidRawWpPost(page)) {
    return fallbackValue;
  }

  return parseRawWpPost(page);
};

export const isIgnoredAttributeKey = (key: string) => {
  // we want to ignore ids...
  if (IGNORED_WP_ATTRS.has(key)) {
    return true;
  }

  return key.startsWith('_');
};

export const isIgnoredReusableKey = (key: string) => {
  return IGNORED_WP_REUSABLE_ATTRS.has(key);
};

/**
 * We want to ingore any empty strings and not send any template strings (for partials)
 */
export const shouldTranslateContentString = (value: string) => {
  // if its empty...
  if (!isNonEmptyString(value)) {
    return false;
  }

  const localizableContent = value.replaceAll(
    new RegExp(TEMPLATE_REGEXP, 'ig'),
    ''
  );

  // if its empty after removing the template strings...
  return isNonEmptyString(localizableContent.trim());
};

export const isLdJsonString = (content: string) => {
  return Boolean(/application\/ld\+json/.test(content) && content.match(/{.*}/s)?.[0]);
};

export const getJsonFromInlineHTML = (inlineHTML: string) => {
  const jsonStr = inlineHTML.match(/{.*}/s)?.[0];

  return jsonStr ? JSON.parse(jsonStr) : {};
};

export const escapeContentSpecialChars = (value: any) => {
  if (!isNonEmptyString(value)) {
    return value;
  }

  return value.replaceAll('"', String.raw`\u0022`);
};

export const getI18nContentForBlockAttr = (
  attributes: WpBlock['attributes'],
  locale: AllLocales,
  attrKeyPath: string,
  { shouldEscapeQuotes } = { shouldEscapeQuotes: true }
) => {
  const attrSegments = attrKeyPath.split('.');

  let i18nContent: Record<string, any> | string =
    attributes?.i18n?.[locale]?.source;
  let fallbackContent: WpBlock['attributes'] = attributes;

  // sometimes we'll get attrs with stringified paths { 'path.to.content': content }
  const shallowContent =
    i18nContent?.[attrKeyPath] || fallbackContent?.[attrKeyPath];

  // no need to do extra work if we can get a shallow value
  if (shallowContent) {
    return shouldEscapeQuotes
      ? escapeContentSpecialChars(shallowContent)
      : shallowContent;
  }

  for (const key of attrSegments) {
    i18nContent = i18nContent?.[key];
    fallbackContent = fallbackContent?.[key];
  }

  const foundConent = i18nContent || fallbackContent || shallowContent;

  return shouldEscapeQuotes
    ? escapeContentSpecialChars(foundConent)
    : foundConent;
};

const getTranslatableI18nAttrContent = (
  attributes: WpBlock['attributes'],
  locale: AllLocales,
  attrKeyPath: string,
  { shouldEscapeQuotes } = { shouldEscapeQuotes: true }
) => {
  const i18nContent = getI18nContentForBlockAttr(
    attributes,
    locale,
    attrKeyPath,
    { shouldEscapeQuotes }
  );

  // return an empty string if we dont want to localize this is an invalid string (empty, etc)
  if (isString(i18nContent) && !shouldTranslateContentString(i18nContent)) {
    return '';
  }

  return i18nContent;
};

/** Recursively runs through json picking out any translatable attrs */
const getNestedJsonStrings = ({
  attributes,
  attrsMap = {},
  locale,
  name,
  pathPrefix = '',
}: {
  attributes: Record<string, any>;
  attrsMap?: { [key: string]: any };
  locale: AllLocales;
  name: string;
  pathPrefix?: string;
}) => {
  const keys = Object.keys(attributes);

  for (const key of keys) {
    // skip if its an ignored attr
    if (isIgnoredAttributeKey(key)) {
      continue;
    }

    if (name === 'reusable-2' && isIgnoredReusableKey(key)) {
      continue;
    }

    // we dont want to send meta i18n values...
    if (/i18n/gi.test(key)) {
      continue;
    }

    const keyPath = pathPrefix ? `${pathPrefix}.${key}` : key;
    const value = getTranslatableI18nAttrContent(attributes, locale, key);

    // handle primitive param assignment (tracking)
    if (isString(value) || isNumber(value)) {
      attrsMap[keyPath] = value;
    }

    // handle nested json (recursion)
    if (isNonEmptyJson(value)) {
      getNestedJsonStrings({
        attributes: value,
        attrsMap,
        locale,
        name,
        pathPrefix: keyPath,
      });
    }

    // if we hit this point, it must have been a null/undefined value (not translatable)
  }

  return Object.entries(attrsMap).map(([key, value]) => ({
    key,
    value
  }));
};

/**
 * This is used specifically so we can reference blocks during/after translations
 * in Phrase.
 */
const makePhraseSegmentId = (block: WpBlock, attrKey: string) => {
  if (!isNonEmptyString(block.attributes?.id)) {
    throw new Error(`Failed to parse block strings, no id found on block. ${JSON.stringify(block)}`);
  }

  /**
   * attrKey is used for nested attrs like field.placeholder where multiple attrs belong to the same blockId.
   * This also helps locate the translated content once the translations are complete.
   * Multiple attrs:
   * `field:label:1092f23e-c22d-428b-a4ef-cb98d4becc62`
   * `field:placeholder:1092f23e-c22d-428b-a4ef-cb98d4becc62`
   * Single attr:
   * `heading:innerContent:1092f23e-c22d-428b-a4ef-cb98d4becc62`
   */
  return `${block.name}:${attrKey}:${block.attributes.id}`;
};

export const getPhraseJobFileFromWpBlocks = (
  blocks: WpBlock[],
  locale: AllLocales,
  result: PhraseJobSourceFile = []
): PhraseJobSourceFile => {
  for (const block of blocks) {
    const { attributes, name } = block;

    if (name === 'meta') {
      const metaStrings = getNestedJsonStrings({
        attributes,
        locale,
        name
      });

      for (const { key, value } of metaStrings) {
        if (value) {
          result.push({
            key: makePhraseSegmentId(block, key),
            note: `${key} (meta)`,
            value: value.toString(),
          });
        }
      }
    }

    if ([
      'heading',
      'paragraph',
      'list'
    ].includes(name)) {
      const innerContent = getTranslatableI18nAttrContent(
        attributes,
        locale,
        'innerContent'
      );

      if (innerContent) {
        result.push({
          key: makePhraseSegmentId(block, 'innerContent'),
          note: `type: ${name}`,
          value: innerContent,
        });
      }
    }

    if ([
      'quote',
      'quote-2',
      'quote-3'
    ].includes(name)) {
      const content = getTranslatableI18nAttrContent(
        attributes,
        locale,
        'content'
      );

      if (content) {
        result.push({
          key: makePhraseSegmentId(block, 'content'),
          note: `type: ${name}`,
          value: content,
        });
      }

      const attrsKeys = ['quote-2', 'quote-3'].includes(name)
        ? ['cite1', 'cite2']
        : ['cite'];

      for (const attrKey of attrsKeys) {
        const attrValue = getTranslatableI18nAttrContent(
          attributes,
          locale,
          attrKey
        );

        if (attrValue) {
          result.push({
            key: makePhraseSegmentId(block, attrKey),
            note: `type: ${name}`,
            value: attrValue,
          });
        }
      }
    }

    if (name === 'button') {
      const text = getTranslatableI18nAttrContent(attributes, locale, 'text');
      if (text) {
        result.push({
          key: makePhraseSegmentId(block, 'text'),
          note: `type: ${name}`,
          value: text,
        });
      }
    }

    if (name === 'wrapper' && attributes.inlineHTML) {
      const html = getTranslatableI18nAttrContent(
        attributes,
        locale,
        'inlineHTML',
        { shouldEscapeQuotes: false }
      );

      if (isLdJsonString(html)) {
        const json = getJsonFromInlineHTML(html);

        if (json.applicationCategory) {
          result.push({
            key: makePhraseSegmentId(block, 'inlineHTML.applicationCategory'),
            note: `type: ${name} (html - application/ld+json)`,
            value: json.applicationCategory,
          });
        }
      }
    }

    if (
      [
        'image',
        'video',
        'wistia',
        'animation'
      ].includes(name) &&
      attributes.pushForI18n
    ) {
      const mediaAlt = getTranslatableI18nAttrContent(
        attributes,
        locale,
        'mediaAlt'
      );

      if (mediaAlt) {
        result.push({
          key: makePhraseSegmentId(block, 'mediaAlt'),
          note: `Alt Text for: <img src="${attributes.mediaURL}" alt="${attributes.mediaAlt}" />`,
          value: mediaAlt,
        });
      }
    }

    if (['field', 'field2'].includes(name)) {
      const placeholder = getTranslatableI18nAttrContent(
        attributes,
        locale,
        'placeholder'
      );

      if (placeholder) {
        result.push({
          key: makePhraseSegmentId(block, 'placeholder'),
          note: 'Placeholder text inside field',
          value: placeholder,
        });
      }

      const label = getTranslatableI18nAttrContent(attributes, locale, 'label');

      if (label) {
        result.push({
          key: makePhraseSegmentId(block, 'label'),
          note: 'Label text before field',
          value: label,
        });
      }

      const pretext = getTranslatableI18nAttrContent(
        attributes,
        locale,
        'pretext'
      );

      if (pretext) {
        result.push({
          key: makePhraseSegmentId(block, 'pretext'),
          note: 'Text before field',
          value: pretext,
        });
      }
    }

    if (name === 'table') {
      const innerContent = getTranslatableI18nAttrContent(
        attributes,
        locale,
        'innerContent'
      );

      result.push({
        key: makePhraseSegmentId(block, 'innerContent'),
        note: 'Table rows',
        value: innerContent,
      });
    }

    if (['select', 'radios'].includes(name)) {
      if (!['Country_Dialing_Code__c'].includes(attributes.name)) {
        for (const key of Object.keys(attributes.opts)) {
          if (key) {
            const escapedKey = key.replaceAll(/\./gi, PERIOD_PLACEHOLDER_KEY);

            result.push({
              key: makePhraseSegmentId(block, `opts.${escapedKey}`),
              note: `Options for ${name}`,
              value: key,
            });
          }
        }

        // some selects have default values set as empty keys
        if (attributes.opts['']) {
          result.push({
            key: makePhraseSegmentId(block, `opts.${EMPTY_OPTION_KEY}`),
            note: `Options for ${name}`,
            value: attributes.opts[''],
          });
        }
      }

      const label = getTranslatableI18nAttrContent(attributes, locale, 'label');

      if (label) {
        result.push({
          key: makePhraseSegmentId(block, 'label'),
          note: `Label before ${name}`,
          value: label,
        });
      }
    }

    if (name === 'submit') {
      const value = getTranslatableI18nAttrContent(attributes, locale, 'value');

      if (value) {
        result.push({
          key: makePhraseSegmentId(block, 'value'),
          note: 'Button text in form',
          value: value,
        });
      }
    }

    // gets translations for partials defaults and reusable overrides
    const isLegacyReusable = name === 'reusable' || name === 'defaults';
    if (isLegacyReusable && isNonEmptyJson(attributes?.params)) {
      const reusableParams = getNestedJsonStrings({
        attributes: attributes.params,
        locale,
        name,
        pathPrefix: 'params',
      });

      for (const { key, value } of reusableParams) {
        if (value) {
          result.push({
            key: makePhraseSegmentId(block, key),
            note: `Template override ${key} (${attributes?.ref})`,
            value: value.toString(),
          });
        }
      }
    }

    if (name === 'reusable-2') {
      const reusableStrings = getNestedJsonStrings({
        attributes,
        locale,
        name,
      });

      for (const { key, value } of reusableStrings) {
        if (value) {
          result.push({
            key: makePhraseSegmentId(block, key),
            note: `${key} (reusable)`,
            value: value.toString(),
          });
        }
      }
    }

    if (Array.isArray(block.innerBlocks) && (
      block.attributes.locales
        ? block.attributes.locales.includes(locale)
        : true
    )) {
      getPhraseJobFileFromWpBlocks(block.innerBlocks, locale, result);
    }
  }

  return result;
};

export const getWpPostIdKey = (type: WpPostTypes) => {
  return `${type}Id`;
};

export const getWpPostId = (type: WpPostTypes, post: WpPost): number => {
  const idKey = getWpPostIdKey(type);

  return post[idKey];
};

const getRecipeCategorySlugs = (categories) => {
  return (
    categories
      ?.map((item) => {
        if (!item) {
          return null;
        }

        if (item?._slug) {
          return item._slug;
        }

        if (typeof item === 'string') {
          return item;
        }

        return null;
      })
      .filter((item) => !!item) || []
  );
};

const formatEnhancedRecipesMetaForStorage = (meta: WpPostMeta) => {
  const {
    _action1,
    _action2,
    _companySizes,
    _functions,
    _industries,
    _integrations,
    _recipeTemplates,
    _ripplingProducts,
    _useCases,
  } = meta.recipes;

  meta.recipes._functions = getRecipeCategorySlugs(_functions);
  meta.recipes._useCases = getRecipeCategorySlugs(_useCases);
  meta.recipes._integrations = getRecipeCategorySlugs(_integrations);
  meta.recipes._recipeTemplates = getRecipeCategorySlugs(_recipeTemplates);
  meta.recipes._ripplingProducts = getRecipeCategorySlugs(_ripplingProducts);
  meta.recipes._companySizes = getRecipeCategorySlugs(_companySizes);
  meta.recipes._industries = getRecipeCategorySlugs(_industries);
  meta.recipes._action1 = getRecipeCategorySlugs(_action1);
  meta.recipes._action2 = getRecipeCategorySlugs(_action2);

  return meta;
};

/**
 * When querying Wordpress for some custom post types, they will be returned as enhanced versions.
 * We ALWAYS want use this before pushing to Wordpress to prevent data contamination.
 * ```json
 * // RETURNED VALUE FROM QUERY
 * meta: {
 *   recipes: {
 *     _functions: [ { _slug: "i-am-a-slug", name: "" } ]
 *   }
 * }
 * ```
 * vs
 * ```json
 * // ORIGINAL STORED VALUE IN WORDPRESS
 * meta: {
 *   recipes: {
 *     _functions: [ "i-am-a-slug" ]
 *   }
 * }
 * ```
 */
export const formatEnhancedWpMetaToRawMetaForStorage = (
  type: WpPostTypes,
  meta: WpPostMeta
): WpPostMeta => {
  if (type === WpPostTypes.Recipe) {
    return formatEnhancedRecipesMetaForStorage(meta);
  }

  return meta;
};

/**
 * Formats meta to a standard block (as its originally stored in Wordpress)
 */
export const metaToBlock = (meta: WpPostMeta): null | WpBlock => {
  return deepCloneJson({
    attributes: { ...meta },
    name: 'meta',
  });
};
