import { isNonEmptyArray } from './validationUtils';

type GqlResponse<T> = {
  data: T;
  errors: Error[];
};

export const postRequestToJSONAPI = async ({
  body,
  headers = {},
  url,
}: {
  body: string;
  headers?: Record<string, unknown>;
  url: string;
}) => {
  const response = await fetch(url, {
    body,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    method: 'POST',
  });

  return await response.json();
};

export const attemptWithRetries = async <T>(
  fn: () => Promise<null | T>,
  retryLimit: number = 5,
  retriesLeft: number = retryLimit
): Promise<null | T> => {
  retriesLeft -= 1;

  try {
    return await fn();
  } catch (error) {
    if (retriesLeft > 0) {
      return await attemptWithRetries(fn, retryLimit, retriesLeft);
    }

    console.error(
      `Failed to run attempted function after ${retryLimit} attempts.\n`,
      error
    );
    throw error;
  }
};

export const fetchAndParseWithRetry = async <T>(
  url: string,
  options?: RequestInit,
  retries?: number
): Promise<T> => {
  const handler = async () => {
    // forcing it to be true here
    const response = await fetch(url, options);

    return await response.json();
  };

  const attemptedResult = await attemptWithRetries<T>(handler, retries);

  if (!attemptedResult) {
    throw new Error(`Failed to fetch and parse. Error output should be above this message. ${JSON.stringify(
      { options },
      undefined,
      4
    )}`);
  }

  return attemptedResult;
};

export const attemptGqlQuery = async <T>({
  headers,
  query,
  url,
  variables,
}: {
  headers?: Record<string, string>;
  query: string;
  url: string;
  variables?: Record<string, unknown>;
}) => {
  const body = JSON.stringify({
    query,
    variables,
  });

  const result = await fetchAndParseWithRetry<GqlResponse<T>>(url, {
    body,
    headers,
    method: 'POST',
  });

  if (isNonEmptyArray(result.errors)) {
    throw new Error(`Encountered graphql request errors: \n${JSON.stringify(
      result.errors,
      undefined,
      4
    )}`);
  }

  return result?.data || null;
};
